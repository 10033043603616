.container-app-topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 8px;
  padding-left: 20px;
  box-shadow: 2px 5px 5px -2px #c8c8c8;
  position: sticky;
  top: 0;
  z-index: 9;
}

@media only screen and (max-width: 600px) {
  .container-app-topbar {
    padding: 5px 4px;
  }
}
.container-right-block{
  display: flex;
  justify-content: right;
}
.excel-file-download{
  background-color: #FCFCFC;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}
.excel-file-download:hover{
  box-shadow: 0px 2px 12px 3px rgb(221 221 221 / 44%);
  transition: all .5s ease;
  
}
.excel-file-download:hover .title{
  color: #25A264;
  transition: all .5s ease;
  
}
.excel-file-download:hover .anticon-file-excel{
  color: #25A264;
  transition: all .5s ease;
}
.excel-file-download .icon{
  transition: color 0.5s ease-out;
  padding-right: 10px;
}
.excel-file-download .title{
  transition: color 0.5s ease-out;
  line-height: 16px;
}
.signout-btn {
  font-size: 14px;
  color: #0F214B;
  font-weight:400;
  color: #000000 !important;
  border: none;
  background-color: #ffffff !important;
  width: -webkit-fill-available;
  text-align: left;
  height: 36px;
}
.signout-btn:active,
.signout-btn:active:focus {
  background-color: #F3F3F3 !important;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15) !important;
}
.signout-btn:hover {
  background-color: #F3F3F3 !important;
}
.pop-content{
  padding: 10px 10px;
 
}
.pop-content h5{
  font-size: 14px;
  color: #0F214B;
  font-weight:300;
  padding-top: 10px;
}
.footer{
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}

.footer-fixed-bottom{
  text-align: center;
  position: relative;
  background-color: #FFF;
  padding-top: 20px;
  padding-bottom: 20px;
  bottom: 0px;
  width: 100%;
}
