.card-container{
    background-color: white;
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); */
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top:20px;
    padding-bottom: 10px;

}
@media only screen and (max-width: 600px) {
    .card-container{
      margin-top: 5px;
    }
  }