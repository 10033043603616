.login-container{
    min-height: 100vh;
    background-color: #FFF;
    justify-content:center;
    display: flex;
    align-items:center;
}
.db-backup-login-container{
    min-height: 71vh;
    background-color: #FFF;
    justify-content:center;
    display: flex;
    align-items:center;
}
.login-form {
    background-color: #fff;
    width: auto;
    padding: 0px 20px;
    border-radius: 5px;
} 
.login-form form{
    border: 1px solid #E9E9E9;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
} 
@media only screen and (max-width: 600px){
    .login-form form {
        border: none;
        padding-left: 0px;
        padding-right: 0px;
    }
}
   
@media only screen and (min-width: 768px){
    .login-form {
        width: 420px;
    }
}
   
.login-form button{
    width: 100%;
    height: 46px;
    text-transform: uppercase;
    background-color: #2B2A7A;
    font-size: 16px;
    border-radius: 4px;   
}
.login-form button:hover {
    background-color: #2B2A7A;
}
.login-form button:focus {
    background-color: #2B2A7A;
}
.login-form button:active {
    background-color: #2B2A7A;
}

.login-form h3{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #27267D;
    font-size: 16px;
    font-family: 'Poppins', sans-serif!important;
}
.login-form h4{
    font-weight: 500;
    margin-top: 10px;
    margin-bottom:  30px;
}
.login-form h5{
    color: #6A6A6A;
    font-size:14px;
}
.login-form .ant-checkbox-inner{
    border-radius: 3px!important;
    border-color: #676767!important;
}
.login-form .ant-input-affix-wrapper{
    border-radius: 5px!important;
}

.centered-block {
    text-align: center;
    padding-bottom: 30px;
    padding-top: 20px;
}
@media only screen and (max-width: 600px){
    .centered-block {
        padding-top: 0px;
    }
}
.centered-block img{
    height: 100px;
}

.custom-green-button button{
    background: #25A264;
    border-color: #25A264;
    border-radius: 4px;
    color: #FFF;
    height: 35px;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
}
.custom-green-button button:hover {
    background: #25A264;
    border-color: #25A264;
    color: #FFF;
}
.custom-green-button button:active {
    background: #25A264;
    border-color: #25A264;
    color: #FFF;
}
.custom-green-button button:focus{
    background: #25A264;
    border-color: #25A264;
    color: #FFF;
}
.download-backup-button button{
    color: #FFF;
    text-transform: capitalize;
    height: 50px;
    font-size: 18px;
}
