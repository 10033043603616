
.ant-table-thead tr th {
  position: sticky;
  top: 0px;
  z-index: 1;
}
.ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  padding: 10px !important;
}

.ant-table-body {
  margin: 0 !important;
}
.ant-modal-title:first-child {
  display: block;
  text-align: center;
}
.ant-spin-lg .ant-spin-dot i {
  width: 20px;
  height: 20px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 50px;
}

.ant-modal-header {
  background: #fdfdfd;
  border-bottom: none;
  border-radius: 15px 15px 0 0;
}
.ant-modal-content {
  border-radius: 15px;
  background-color: #fdfdfd;
}
.ant-input-affix-wrapper {
  line-height: 2;
}
.ant-radio-button-wrapper {
  height: 52px;
  font-size: 22px;
  line-height: 50px;
}
.ant-table-thead > tr > th {
  background: #d6d6d6!important;
  color: #000;
}
.ant-table-thead > tr:hover > th {
  background: #d6d6d6;
  color: #000;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f0f0f0;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgb(82, 82, 82);
}
.ant-table-thead th.ant-table-column-sort {
  background: rgb(82, 82, 82);
}
td.ant-table-column-sort {
  background: none;
}
.ant-select {
  font-size: 15px;
}

@media (max-width: 600px) {
  .ant-picker-panels { 
    flex-direction: column;
  } 
}
.ant-form-item-label > label{
  height: auto;
}