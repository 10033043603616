
.form-basic-container{
   background-color: #ececf5;
   margin: 0 auto;
   border-radius: 5px;
   padding-bottom: 30px;
}
.form-basic-container .ant-picker{
    width: 100%;
}
.form-basic-container .ant-select-arrow{
    color: #000;
}
.form-basic-container .ant-select-single .ant-select-show-arrow .ant-select-selection-item{
    padding-right: 60px;
}
.form-basic-container .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 60px;
}
@media only screen and (min-width: 992px) {
    .form-basic-container .form-row-block .ant-col:nth-of-type(1){
        padding-right: 12px;
    }
}
@media only screen and (min-width: 992px) {
    .form-basic-container .form-row-block .ant-col:nth-of-type(2){
        padding-right: 12px;
    }
}






.form-title{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items:center;
    height: 50px;
    box-shadow: 2px 5px 5px -2px #c8c8c8;
    position: sticky;
    top: 0;
    z-index: 9;
    padding: 40px 20px;
    background-color: #FFF;
}
.form-title h3{
    font-weight: 600;
    margin-bottom: 0px;
    color: #1890ff;
}
@media only screen and (max-width: 600px) {
   .form-title{
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 10px;
        padding-left: 0px;
   }
}

.gray-button button{
    background: #F1F2F6;
    border-color: #F1F2F6;
    color: #000;
}

.gray-button button:hover{
    background: #F1F2F6;
    border-color: #F1F2F6;
    color: #000;
}
.gray-button button:active{
    background: #F1F2F6;
    border-color: #F1F2F6;
    color: #000;
}
.gray-button button:focus{
    background: #F1F2F6;
    border-color: #F1F2F6;
    color: #000;
}

.no-styled-button button{
    background: #FFF;
    border-color: #FFF;
    color: #000;
    box-shadow: none;
    font-size: 14px;  
}
.no-styled-button button:hover{
    background: #FFF;
    border-color: #FFF;
    color: #000;
}
.no-styled-button button:active{
    background: #FFF;
    border-color: #FFF;
    color: #000;
}
.no-styled-button button:focus{
    background: #FFF;
    border-color: #FFF;
    color: #000;
}

.flex-center{
   display:flex;
   justify-content: center;
}

.button-gap button {
    margin: 0px 10px;
}
.form-block-container {
    padding: 20px 10px 10px 20px;
    background-color: #FFF;
    border-radius: 5px;
    width: 100%;
    margin-top: 25px;
    box-shadow: 0px 5px 7px -7px rgba(94,94,94,0.57);
}
.form-block-colored-container{
    border-top: 5px solid #27267D;
    padding: 20px 10px 10px 20px;
    background-color: #FFF;
    border-radius: 5px;
    width: 100%;
    margin-top: 25px;
    box-shadow: 0px 5px 7px -7px rgba(94,94,94,0.57);
}
.form-block-header{
    font-size: 18px;
    color: #27267D;
}
.form-container{
    max-width: 992px;
    margin: 30px auto;
}
@media only screen and (max-width: 600px) {
    .form-container{
        margin: 30px 5px;
    }
}
.custom-spin-container{
    height: 60vh;
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
}
.custom-spin-container h5{
    margin-top:20px;
}
.responsive-table {
    width: max-content;
    min-width: 100%;
    table-layout: auto; 
    display:contents; 
}
@media only screen and (max-width: 600px) {
    .responsive-table {
        display: inline-table;
    }
}
.responsive-table thead tr td{
    padding-bottom:10px;
}
.responsive-table th, td {
    padding: 5px;
  }
.vertical-centered{
    display: flex;
    align-items: center;
}
.vertical-centered label{
    padding-left: 5px;
}
.horizontal-seperator{
    border: 1px solid #d9d9d9;
    margin-bottom: 25px;
}
.text-center{
    text-align: center;
}
.radio-group-component{
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #f3f3f3;
}
.radio-group-component .ant-form-item-label{
    text-align: left;
    font-weight: 600;
}
@media only screen and (max-width:768px) {
    .ant-radio-wrapper{
        display: block;
    }
}